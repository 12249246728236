/* Reset & Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
  }
  
  .pita-container {
    position: relative;
    text-align: center;
    color: #333;
    padding: 20px;
  }
  
  /* Background */
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.3; /* Adjust background transparency */
  }
  
  /* Logo Section */
  .pita-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .pita-logo {
    width: 250px;
    max-width: 90%;
    margin-bottom: 10px;
  }
  
  .tagline {
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
  }
  /* Contact Info */
.contact-info {
    background-color: #4277ea; /* Light peach color */
    color: #000; /* Set text color to black */
    display: flex;
    justify-content: space-between; /* Spread items evenly */
    align-items: center;
    padding: 15px 20px; /* More padding for better spacing */
    font-size: 1.2rem;
    border-radius: 10px;
    margin: 15px auto;
    max-width: 80%; /* Stretches but not too wide */
    width: 100%;
  }
  
  /* Ensures all links and text inside are black */
  .contact-info a {
    color: #000;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .contact-info a:hover {
    color: #e65100; /* Orange hover effect */
    text-decoration: underline;
  }
  
  /* Icons styling */
  .contact-info span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-info {
      flex-direction: column;
      text-align: center;
      gap: 10px;
    }
  }
  
  
  /* Address Section */
  .pita-address {
    background: white;
    padding: 15px;
    border-radius: 10px;
    max-width: 500px;
    margin: 20px auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .pita-address h3 {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  
  /* Menu Section */
  .pita-menu {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 20px auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .menu-image {
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
  
 /* Hours of Operation Styling */
.pita-footer {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    margin: 20px auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .pita-footer h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  .closed-notice {
    color: red;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Styling the Hours Section */
  .hours-table {
    line-height: 1.8;
    text-align: center;
    font-size: 1rem;
  }
  
  .hours-table p {
    margin: 10px 0;
  }
  
  .closed {
    color: red;
    font-weight: bold;
  }
  