/* src/App.css */

body {
  margin: 0;
  font-family: 'Comic Neue', sans-serif;
  background-color:rgba(244, 243, 248, 0.8);
  /* Light background color */
}

button{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: large !important;
}

html, body {
  height: auto !important;
}
.App-header {
  background-color: #491471; /* Bright header color */
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-logo {
  height: 150px;
}

.App-logo1 {
  height: 150px;
  align-items: center;
}

.header-title {
  font-family: 'Comic Neue', cursive;
  font-size: 1.0em;
  color: white;
  text-align: left;
}

.container {
  display: grid;
  grid-template-columns: 300px 1fr; /* Default width for the links-section */
  grid-template-rows: 0px 1fr;
  background-color: #B7E5FF; /* Light background color */
  height: 100vh;
}

.links-section {
  background-color: #38B6FF; /* Bright side panel color */
  padding: 20px;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  overflow-y: auto;
  width: 180px; /* Default width */
  max-width: 200px; /* Default width */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.links-section h3 {
  color: #FFDE59;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.main-content {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  padding: 20px;
  overflow-y: auto;
  background-color: #B7E5FF; /* Light background color */
  height: 70%;
}

.product-categories-header {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

h2 {
  margin-top: 0;
}

.links-list {
  list-style-type: none;
  padding: 0;
  max-width: 180px;
}

.links-list li {
  margin-bottom: 10px;
  font-weight: bold;
  font-family: 'Comic Neue', cursive; /* Use Comic Neue font */
  color: #3d0b4e;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.76); /* 3D effect */
}

a {
  text-decoration: none;
  color: #210224;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #38B6FF; /* Bright navbar color */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.nav-button {
  background-color: #38B6FF;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  color: white;
}

.navbar-right a {
  margin-right: 10px;
  text-decoration: none;
  color: white;
  font-size: 0.9em;
}

.search-bar {
  padding: 5px;
  margin-right: 10px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.menu-items {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-items.open {
  display: block;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 350px 1fr; /* Increased width for medium screens */
  }

  .navbar-right {
    max-height: 0;
    overflow: hidden;
    flex-direction: column;
  }

  .navbar-right.open {
    max-height: 300px;
  }

  .menu-toggle {
    display: block;
  }

  .menu-items {
    flex-direction: column;
    width: 100%;
    background-color: #38B6FF;
  }

  .menu-items li {
    text-align: center;
    padding: 10px 0;
  }

  .search-bar {
    margin: 10px auto;
    width: 80%;
  }

  .links-section {
    padding: 10px;
    width: 350px; /* Increased width for medium screens */
    max-width: 350px; /* Increased width for medium screens */
  }

  .App-logo {
    height: 100px; /* Adjust the height for smaller screens */
  }
}

@media (max-width: 480px) {
  .App-logo {
    height: 100px; /* Adjust the height for smaller screens */
    width: auto;
  }

  .header-title {
    font-size: 1em;
  }

  .links-section {
    padding: 2px;
    width: 150%; /* Increased width for small screens */
    max-width: 150%; /* Increased width for small screens */
 
  }

  .container {
    grid-template-columns: 320px 1fr; /* Increased width for small screens */
  }

  .links-list li {
    margin-bottom: 5px;
  }

  .nav-button {
    padding: 5px 10px;
    margin: 5px 0;
  }

  .ad-section {
    padding: 5px;
    max-width: 50px;
  }
}

/* Main Categories Styling */
.main-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.category {
  background-color: #38B6FF;
  color: #FFDE59(10, 2, 56);
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  flex-basis: calc(25% - 40px);
  text-align: center;
}

.category-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.types .type {
  margin-bottom: 5px;
}

/* Modals for Sign In and Sign Up */
.small-modal {
  width: 300px;
  height: auto;
  max-width: 50%;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: auto;
}

.modal-content {
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  margin-top: 5px;
  font-weight: bold;
}

form input {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 1em;
}

.primary-button {
  padding: 10px;
  font-size: 1em;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.primary-button:hover {
  background-color: hsl(48, 92%, 80%);
}

.secondary-button {
  padding: 10px;
  font-size: 1em;
  margin-top: 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: #5a6268;
}

.close-button {
  padding: 10px;
  font-size: 1em;
  margin-top: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  background-color: #c82333;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 10px 0;
}

.message {
  color: red;
  font-weight: bold;
}

.forgot-section {
  margin-top: 10px;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden-links {
  display: none;
}

.hidden-links.open {
  display: block;
}

/* Contact Us Page */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #B7E5FF;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.contact-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Community Hub Adjustments for Mobile View */
@media (max-width: 768px) {
  .category-section {
    margin-bottom: 40px; /* Increase the space between category sections */
  }

  .sub-community-container {
    display: block;
    text-align: center;
  }

  .sub-category {
    display: inline-block;
    margin: 10px 5px;
  }

  .sub-community-tile {
    padding: 10px;
    width: auto;
    height: auto;
  }

  .sub-sub-community-container {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }

  .sub-category:hover {
    margin-bottom: 10px; /* Adjust this value to maintain consistency */
  }
}

.flag-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.links-list li {
  display: flex;
  align-items: center;
}

.slick-dots{
  display: none !important;
}

@media (max-width: 516px) {
  .adjustable-text {
   
    font-size: small;
  }
}
  @media (max-width: 475px) {
    .adjustable-text {
     
      font-size: x-small;
      display: inline-block; /* Makes the span respect width and min-width */
      min-width: 50px;      /* Ensures at least 100px horizontal space */
      white-space: nowrap;
    }

}
