.vendor-template3-container {
  background-color: #1a1a1a; /* Dark theme background */
  color: #f5f5f5; /* Light text color for contrast */
  font-family: Arial, sans-serif;
  padding: 20px;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #282828; /* Slightly lighter shade for hero section */
  margin-bottom: 30px;
  border-radius: 10px;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-section p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* About Section */
.about-section {
  text-align: center;
  margin-bottom: 40px;
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.learn-more-button {
  background-color: #f04e23;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

/* Product Grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.product-card {
  background-color: #2c2c2c;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  margin-bottom: 15px;
}

.product-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.product-card p {
  font-size: 0.9rem;
  color: #d1d1d1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .about-section h2 {
    font-size: 1.5rem;
  }

  .product-card img {
    height: 150px;
  }
}
