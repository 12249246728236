/* farmersmarket.css */

.farmersmarket-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to bottom, #f9f9f9, #e8e8e8);
    font-family: 'Arial', sans-serif;
    padding: 20px;
  }
  
  /* Center all items in a vertical column */
  .categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  /* Styling for the "Coming Soon" box */
  .category-tile {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 22px;
    font-weight: bold;
    color: #555;
    text-align: center;
    width: 250px;
  }
  
  /* Slogan styling */
  .slogan-container {
    text-align: center;
    padding: 20px 30px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .slogan {
    font-size: 24px;
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
  }
  
  /* Centering the back button */
  .back-home-tile {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .back-home-link {
    text-decoration: none;
    background: #350a58;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .back-home-link:hover {
    background: #217dbb;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .slogan-container {
      padding: 15px 20px;
      font-size: 20px;
    }
  
    .category-tile {
      width: 200px;
    }
  
    .back-home-link {
      font-size: 16px;
    }
  }
  