/* ============================= */
/* HEADER CONTAINER */
/* ============================= */
.header-container {
  display: flex;
  justify-content: space-between;
  background-color: #25043d;
  min-height: 80px;
  color: #e7deee;
 
}

/* ============================= */
/* LOGO SECTION */
/* ============================= */
.logo-section {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Center logo, text, and tagline */
  flex-wrap: nowrap; /* Keeps everything on one line */
  gap: 5px;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

/* Logo Image */
.logo-image {
  height: 80px;
  max-width: 100px;
}

/* GoOurHub Text */
.logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #f8c433;
  font-family: "Poppins", sans-serif;
  text-transform: none;
  white-space: nowrap; /* Prevents breaking into multiple lines */
}

/* Connecting You - Cursive Text */
.connecting-text {
  color: #ffcc00;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: 'Dancing Script', cursive;
  white-space: nowrap; /* Keeps everything in one line */
}

/* ============================= */
/* SOCIAL MEDIA SECTION */
/* ============================= */
.social-media-section {
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  color: #ffc107;
}

.social-icons {
  display: flex;
  gap: 5px;
}

.social-icon i {
  font-size: 15px;
  color: #FFD700;
}

.social-icon:hover i {
  color: hsl(48, 92%, 80%);
}

/* ============================= */
/* HEADER PAIR CONTAINER */
/* ============================= */
.header-pair-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

/* ============================= */
/* BUTTONS SECTION */
/* ============================= */
.header-buttons {
  display: flex;
  gap: 2px;
}

/* Sign-In and Sign-Up Buttons */
.sign-in, .sign-up {
  background-color: #e7deee;
  color: #25043d;
  font-size: 16px;
  padding: 6px 14px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: auto;
}

.sign-in:hover, .sign-up:hover {
  background-color: hsl(48, 92%, 80%);
  color: #25043d;
}

/* Navigation Buttons */
.nav-buttons {
  display: flex;
  margin: 20px;
  gap: 2px;
}

.nav-button {
  background-color: #e7deee;
  color: #1f0331;
  border: none;
  padding: 10px 15px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-button:hover {
  background-color: hsl(48, 92%, 80%);
  color: rgb(24, 3, 3);
}

/* ============================= */
/* SEARCH SECTION */
/* ============================= */
.search-container {
  position: relative;
  width: 250px;
  margin: 0 auto;
  border: 1px solid #f8c433;
  border-radius: 25px;
  display: flex;
  align-items: center;
  background-color: #25043d;
  padding: 5px 10px;
  height: 35px;
}

.search-container:hover,
.search-bar:focus {
  border-color: #f1c40f;
  box-shadow: 0 0 6px rgba(241, 196, 15, 0.8);
}

.search-icon {
  font-size: 18px;
  color: #e7c21a;
  margin-right: 10px;
}

.search-bar {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  color: #e6e0eb;
  background-color: transparent;
}

.search-bar::placeholder {
  color: #b3b3b3;
}

.mic-button {
  background-color: #f8c433;
  color: #25043d;
  border: none;
  border-radius: 20%;
  padding: 1px;
  cursor: pointer;
  padding-top: 5px;
}

.mic-button:hover {
  background-color: #cfa4f1;
}
/* ============================= */
/* DASHBOARD & NAVIGATION */
/* ============================= */
.dashboard-link {
  background-color: #dfd1e4;
  border-radius: 8px;
  color: #1f0331;
  font-size: medium;
  padding: 5px 10px;
}

.highlight {
  background-color: #a936f7 !important;
  font-weight: bold;
  color: black;
}

/* ============================= */
/* MENU & HAMBURGER SECTION */
/* ============================= */
.hamburger-container {
  display: block !important;
  position: relative;
}

.hamburger-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 40px;
  right: 150px;
  background-color: #8a31c9;
  font: 0.8em sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 150px;
}

.menu-item {
  display: block;
  padding: 8px 12px;
  color: #e7deee;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item:hover {
  background-color: #f1d67d;
  color: #25043d;
}

/* ============================= */
/* JOIN US BUTTON */
/* ============================= */
.join-us-section {
  margin-top: 5px;
}

.join-us-button {
  display: inline-block;
  padding: 8px 12px;
  font-size: 10px;
  font-weight: bold;
  color: black;
  background-color: #ffcc00;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.join-us-button:hover {
  background-color: #e6b800;
  transform: scale(1.05);
}

/* ============================= */
/* RESPONSIVE DESIGN */
/* ============================= */
@media (max-width: 768px) {
  .logo-section {
    justify-content: flex-start !important; /* Ensures even spacing */
    gap: 5px;
  }

  .logo-image {
    height: 40px;
   
  }

  .logo-text {
    font-size: 12px;
  }

  .connecting-text {
    font-size: 0.2rem;
  }
  .hamburger-container {
      display: block;
  }

  .dashboard-link {
      display: none;
  }

  .nav-buttons {
      display: flex;
  }

  .menu-dropdown {
      display: flex;
      flex-direction: column;
      gap: 5px;
  }
  
  .connecting-text {
      font-size: 0.7rem;
  }
   

  .nav-button {
      width: 100%;
      padding: 10px 15px;
      font-size: 10px;
  }
}

@media (min-width: 769px) {
  .hamburger-container {
      display: none;
  }
}

@media (max-width: 891px) {
  .header-container {
      flex-direction: column;
      height: auto;
      padding: 0px;
  }

  .logo-section {
    
    align-items: center;
    text-align: center;
}

.logo-text {
    font-size: 18px; /* Smaller GoOurHub text */
}

.connecting-text {
    font-size: 0.6rem; /* Adjust cursive text size */
}

.logo-image {
    height: 70px;
}

  .social-media-section {
      flex-direction: column;
      width: 30%;
      align-items: center;
      margin-bottom: 5px;
      gap: 2px;
  }

  .header-pair-container {
      width: 100%;
  }

  .nav-button {
      padding: 2px 5px;
      font-size: 8px;
      border-radius: 2px;
  }
}

@media (max-width: 414px) {
  .logo-section {
    justify-content: center;
    flex-wrap: nowrap;
    gap: 3px;
  }

  .logo-image {
    height: 50px;
    max-width: 70px;
  }

  .logo-text {
    font-size: 18px;
  }

  .connecting-text {
    font-size: 0.5rem;
  }
  .social-icons {
      gap: 2px;
      align-items: center;
      justify-content: center;
  }

  .sign-in, .sign-up {
      font-size: 14px;
  }
  
}
