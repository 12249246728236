/* Template design */

/* --- PU all constants here, so that easy to change if needed */
:root {
    --background-color: ##f7f0fb; 
    --hover-color:  hsl(48, 92%, 80%); 
  }
  
  .headerSection { grid-area: header; position: fixed; z-index: 9999; width: 100%;}
  .menuSection { grid-area: menu; margin-top: 60px;}
  .mainSection { grid-area: main; margin-top: 120px;}
  
  .rightNavSection { grid-area: right; margin-top: 100px; }
  .footerSection { grid-area: footer; }
  
  .grid-container {
    display: grid;
    grid-template-areas:
      'header header header header header header header header header'
      'menu main main main main main main main right'
      'footer footer footer footer footer footer footer footer footer';
    gap: 1px;
    background-color: var(--background-color);
    padding: 2px;
  }
  
  /* For mobile right nave goes at the bottom */
  @media (max-width: 750px){
  .grid-container {
    display: grid;
    grid-template-areas:
      'header header header header header header'
      'right right right right right right'
      'menu menu menu menu menu menu'
      'main main main main main main'
      'footer footer footer footer footer footer';
    gap: 1px;
    background-color: var(--background-color);
    padding: 5px;
  }

  .mainSection { grid-area: main; margin-top: 0px;}
  .menuSection { grid-area: menu; margin-top: 10px;}
  
  }
  
  .grid-container > div {
    background-color: rgba(244, 243, 248, 0.8);
    text-align: center;
    padding: 2px 0;
    font-size: 25px;
  }
  
  
  @media (max-width: 500px){
    .grid-container > div 
    {
      background-color: rgba(244, 243, 248, 0.8);
      text-align: center;
      padding: 1px 0;
      font-size: 16px;
    }

    .rightNavSection { grid-area: right; margin-top: 170px; }
  
  }
  
  @media (max-width: 360px){
    .grid-container > div 
    {
      background-color: rgba(244, 243, 248, 0.8);
      text-align: center;
      padding: 0    px 0;
      font-size: 14px;
    }
  
  }