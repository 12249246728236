/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
  }
  
  .selam-food-mart {
    text-align: center;
    background-color: #f9f9f9;
    padding-bottom: 30px;
  }
  
  /* Hero Section */
  .hero {
    position: relative;
    height: 60vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-content {
    background: rgba(0, 0, 0, 0.65);
    color: white;
    padding: 20px 40px;
    border-radius: 10px;
    position: relative; /* Ensures it moves within hero section */
    margin-top: 300px; /* Adjust this value to move it further down */
  }
  
  .hero-title {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .hero-subtitle {
    font-size: 1.4rem;
  }
  
  /* Store Info */
  .store-info {
    padding: 40px 20px;
  }
  
  .store-info h2 {
    font-size: 2rem;
    color: #004d40;
    margin-bottom: 10px;
  }
  
  .store-info p {
    font-size: 1.2rem;
    color: #444;
  }
  
  /* Address Section */
  .address {
    background: #0a5127 !important;
    padding: 20px;

    border-radius: 10px;
    margin: 20px auto;
    width: 100%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .highlighted-text {
    font-size: 1.2rem;
    font-weight: bold;
    color: #0b391f;
  }
  
  .phone {
    font-size: 1.1rem;
    color: #444;
  }
  
  /* Highlights */
/* Highlights Section */
.highlights {
    background: linear-gradient(135deg, #033712, #024c1c); /* Orange gradient */
    color: white;
    padding: 25px;
    border-radius: 12px;
    width: 90%;
    margin: 20px auto;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .highlights:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  
  .highlights h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .highlights ul {
    list-style: none;
    font-size: 1.2rem;
    padding: 0;
  }
  
  .highlights li {
    margin: 8px 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .highlights {
      width: 70%;
    }
  }
  
  @media (max-width: 768px) {
    .highlights {
      width: 90%;
    }
  }
  
  /* Product Display */
  .product-display {
    margin-top: 30px;
  }
  
  .product-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: auto;
    max-width: 80%;
  }
  
  .product-item {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Product Colors */
  .fresh {
    background: #e8f5e9;
    border-left: 6px solid #388e3c;
  }
  
  .spices {
    background: #d2f1ce;
    border-left: 6px solid #04442b;
  }
  
  .groceries {
    background: #e5f5eb;
    border-left: 6px solid #086b2b;
  }
  
  /* Gallery */
  .gallery {
    margin-top: 30px;
    padding: 0; /* Remove padding to make it seamless */
    width: 100%;
    background: none; /* Remove background */
  }
  
  /* Remove "Store Gallery" heading */
  .gallery h2 {
    display: none;
  }
  
  /* Full-width gallery with seamless tile effect */
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 2px; /* Minimized spacing */
    width: 100%;
    margin: 0;
  }
  
  /* Ensure all images are the same size */
  .gallery-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gallery-img {
    width: 100%;
    height: 220px; /* Uniform height */
    object-fit: cover;
    border-radius: 0; /* No border-radius to create a seamless grid */
    box-shadow: none; /* Remove shadow for seamless tile effect */
    transition: transform 0.2s ease;
  }
  
  .gallery-img:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .gallery-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns for tablets */
    }
  
    .address,
    .highlights {
        width: 100%;
        color: rgb(215, 128, 13);
    }
  }
  
  @media (max-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
    }
  
    .address,
    .highlights {
      width: 100%;
      color: rgb(215, 128, 13);
    }
  }
  
  @media (max-width: 480px) {
    .gallery-grid {
      grid-template-columns: repeat(1, 1fr); /* Full width on mobile */
    }
  
    .gallery-img {
      height: 180px;
    }
  }
  