@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* Main Container */
.about-us-container {
    max-width: 900px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f9f2ff; /* White background for readability */
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
    line-height: 1.6; /* Better readability */
    font-family: 'Poppins', sans-serif; /* Modern and elegant font */
    text-align: center; /* Center text alignment for a clean look */
}

/* Highlighted Text */
.about-us-container span {
    font-weight: bold;
    color: #4a096d; /* Accent color for emphasis */
    font-size: 1.2em; /* Slightly larger for emphasis */
}

/* Logo Section */
.about-us-logo img {
    max-width: 200px; /* Resize the logo */
    margin-bottom: 20px;
}

/* Collaboration Section */
.collaboration-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #f1e0fd; /* Light background for contrast */
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.collaboration-section h3 {
    font-size: 1.8rem;
    color: #4A148C; /* Vibrant purple */
    margin-bottom: 15px;
}

.collaboration-section p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.8;
}

.contact-link {
    color: #00796B; /* Accent color */
    font-weight: bold;
    text-decoration: none;
    font-size: 1.2rem;
}

.contact-link:hover {
    color: #004D40; /* Darker shade on hover */
    text-decoration: underline;
}


/* Adjust container for spacing */
.about-us-container p {
    font-size: 1.2rem;
    color: #333; /* Dark gray text for readability */
    text-align: justify;
    margin-bottom: 20px;
    line-height: 1.8; /* Better readability */
}

/* Add spacing between sections */
.about-us-container h2 {
    font-size: 2.5rem;
    color: #4A148C; /* Vibrant purple for the title */
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
}

/* Smaller screens */
@media (max-width: 768px) {
    .about-us-container {
        max-width: 90%;
        padding: 15px;
        font-size: 1rem;
        margin: 20px auto;
    }

    .about-us-container h2 {
        font-size: 2rem; /* Smaller title for mobile */
    }

    .about-us-container p {
        font-size: 1rem; /* Adjust text size for readability */
        text-align: left; /* Align left for better mobile readability */
    }

    .collaboration-section {
        padding: 15px;
    }

    .collaboration-section h3 {
        font-size: 1.5rem;
    }

    .collaboration-section p {
        font-size: 1rem;
    }

    .contact-link {
        font-size: 1rem;
    }

    .about-us-logo img {
        max-width: 150px; /* Adjust logo size for mobile */
    }
}
