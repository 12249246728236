.vendor-template1-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to right, #fce3ec, #e3d7fc); /* Gradient background */
  min-height: 100vh;
  box-sizing: border-box;
}

.about-section {
  display: flex;
  flex-wrap: wrap; /* Wrap items for smaller screens */
  width: 90%;
  max-width: 1200px;
  gap: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow: hidden;
}

/* Left Panel */
.about-left {
  flex: 1 1 100%; /* Full width on smaller screens */
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
}

.about-left img {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
}

h2 {
  margin: 10px 0;
}

p {
  margin: 5px 0;
  color: #555;
}

.email-button {
  background-color: #3d0e5f;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  font-size: 1.5rem;
}

.social-icons i {
  color: #d0b803;
  cursor: pointer;
}

/* Right Panel */
.about-right {
  flex: 1 1 100%; /* Full width on smaller screens */
  padding: 20px;
  text-align: center;
}

.about-right h2 {
  margin-bottom: 20px;
}

.product-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive grid */
  gap: 10px;
}

.product-gallery img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.catalog-button {
  background-color: #3d0e5f;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .about-section {
    flex-direction: column; /* Stack items on smaller screens */
    padding: 10px;
  }

  .about-left img {
    max-width: 200px;
  }

  .product-gallery img {
    height: 120px; /* Adjust image height for smaller screens */
  }

  .email-button,
  .catalog-button {
    width: 100%; /* Full width buttons on smaller screens */
  }
}
