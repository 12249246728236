.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .thank-you-box {
    background: white;
    padding: 40px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  
  .thank-you-box h1 {
    color: #007bff;
    font-size: 26px;
    margin-bottom: 10px;
  }
  
  .thank-you-box p {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .thank-you-box a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .thank-you-box a:hover {
    text-decoration: underline;
  }
  
  .home-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.3s;
  }
  
  .home-button_ThankyouP:hover {
    background-color: #440f5f;
  }
  