.business-card {
    width: 100%;
    height: 100vh;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-header {
    background: #0077cc;
    color: white;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    width: 80%;
    font-size: 1.5em;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 80%;
  }
  
  .card-info {
    text-align: center;
    font-size: 1.2em;
  }
  
  .owner {
    font-style: italic;
    font-size: 1.1em;
  }
  
  .card-image img {
    width: 200px;
    border-radius: 5px;
    margin-top: 15px;
  }
  
  .card-footer {
    background: #e0e0e0;
    padding: 15px;
    border-radius: 0 0 10px 10px;
    width: 80%;
    font-size: 1.2em;
  }
  