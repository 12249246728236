/* Visitgroceries.css */

.visit-groceries-page {
    padding: 20px;
  }
  
  .categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-bottom: 20px; /* Add space between category tiles and the back-home tile */
  }
  
  .category-tile {
    width: 200px;
    height: 200px;
    background-color: #f0f0f0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .category-tile:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .category-link {
    text-decoration: none;
    color: #333;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .category-icon {
    font-size: 50px;
    margin-bottom: 10px;
  }
  
  .category-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  /* Back to homepage slim long tile styling */
  .back-home-tile {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 0;
    background-color: #e0e0e0;
    border-radius: 8px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .back-home-tile:hover {
    background-color: #d0d0d0;
  }
  
  .back-home-link {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  