

  @media(max-width:750px){

    .menu-section {
      background-color: #25043d; /* Bright side panel color */
      margin-top: 0px !important;
      padding: 5px;
    }
  }

  @media(min-width:750px){

    .menu-section {
      background-color: #200a31; /* Bright side panel color */
      margin-top: 60px;
      padding: 5px;
     /* grid-column: 1 / 2;
      grid-row: 2 / 3;
      /*overflow-y: auto;*/
      width: 100%; /* Default width */
      height: 100%;
      /*max-width: 200px; /* Default width */
      /*box-sizing: border-box; /* Ensure padding is included in width */
    }
  }
  
  .menu-section h3 {
    color: #25043d;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .menu-section span{
    /*padding-top: 15px;*/
    color: hsl(317, 30%, 91%);
    font-size: small;

  }