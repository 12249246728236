/* Container for the carousel */
.sliding-ad-carousel-container {
    overflow: hidden;
    width: 100%;
    height: 200px;
    position: relative;
    /*border: 1px solid red;*/
  }
  
  /* Individual slide */
  .sliding-ad-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    /*border: 2px solid rgb(34, 21, 211);*/
  }
  
  /* Content inside each ad */
  .sliding-ad-section {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 100%;
    padding: 2px;
    /* border: 2px solid rgb(7, 95, 27);*/
  }

  /*add content image if any */
  .sliding-ad-image {
    width: 100%;
    height: 100%;

  }
  
  /* Specific styles for each ad background */
  .ad-red {
    background-color: red;
  }
  
  .ad-blue {
    background-color: blue;
  }
  
  .ad-green {
    background-color: green;
  }

  /* if needed */
  