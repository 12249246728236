.footerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #210238 !important;
 
    color:  #210238;
  }
  
  .footerLinks {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px !important;
  }
  
 
  .footerLink:hover {
    text-decoration: underline;
  }
  
  .socialIcons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .socialIcon {
    color: white;
    font-size: 1.2rem;
    transition: transform 0.2s;
  }
  
  .socialIcon:hover {
    transform: scale(1.2);
  }
  
  .footerText {
    display: flex;
    justify-content: space-between; /* Positions items side-by-side */
    align-items: center;
    font-size: 14px;
  }
  
  .footerSection {
    width: 100%;
  }
  
  .back-to-top-strip {
    background-color: #382b41; /* Dark Slate Gray (Change to your preference) */
    color: white;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .back-to-top-strip:hover {
    background-color: #341649; /* Darker color on hover */
  }
  
  .footer-content {
    background-color: #210238; /* Footer background color */
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .footerText {
    font-size: 14px;
    margin-top: 10px;
  }
  