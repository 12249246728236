.forum-post {
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(96, 3, 3, 0.1);
  }
  .forum-link {
    color: #2a0958; /* Custom c
 
  }
  
  .forum-link:hover {
    color: #2f0962; /* Change color on hover */
  }
  
  
  .forum-form input,
  .forum-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .forum-form button {
    background-color: #6200ea;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  .forum-form button:hover {
    background-color: #4e00b6;
  }
  