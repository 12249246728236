/* src/SignUp.css */
.sign-up-container {
  max-width: 400px;
  margin: 40px auto; /* Adds space from the top of the viewport */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow for better visibility */
  position: relative;
  z-index: 1010; /* Ensures it's above other elements */
}

/* Center the form vertically if needed */
@media (min-height: 700px) {
  .sign-up-container {
      top: 20%; /* Center it more vertically on tall screens */
  }
}

.sign-up-container h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-size: 1.8rem; /* Adjust heading size for emphasis */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px; /* Slightly larger padding for better usability */
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #00aaff;
  outline: none;
}

button {
  
  padding: 12px; /* Larger padding for better clickability */
 
  color: rgb(41, 5, 52);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem; /* Slightly larger text size */
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #732678;
  color: #ebe7ec;
  transform: scale(1.02); /* Adds a subtle scaling effect */
}

button:active {
  background-color: #700099;
  transform: scale(0.98); /* Adds a pressed effect */
}

/* Additional Spacing and Layout Adjustments for Mobile Screens */
@media (max-width: 768px) {
  .sign-up-container {
      padding: 15px;
      margin: 20px auto; /* Reduce margin for smaller screens */
  }

  button {
      font-size: 0.9rem;
      padding: 10px;
  }
}
