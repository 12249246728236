.left-align {

    text-align: left; 
}


.right-align {

    text-align: right; 
}


.left-padding {

    padding-right: 5px; 
}