.new-tile {
    width: 100px;
    height: 95px;
    float: left;
    margin: 2px;
    background-color: hsl(80, 14%, 91%);
    border-radius: 20px;
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    text-align: center; /* Align text */
    /* size: 9px;  Default font size */
    flex-direction: column;
    box-shadow: 10px 5px 12px 2px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease; /* Smooth hover effect */
}

.new-tile:hover {
    background-color: hsl(48, 92%, 80%); /* Slightly darker background color on hover */
    transform: scale(1.1); /* Slightly increase size */
    box-shadow: 12px 8px 15px 3px rgba(0, 0, 0, 0.3); /* Slightly more prominent shadow */
}

.new-tile .icon-for-new-tile {
 /* Fixed size */
    object-fit: contain; /* Ensures the image scales proportionally */
    font-size: 40px; /* Adds spacing between the icon and text */
  }

.new-tile-container {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    flex-wrap: wrap; /* Allow wrapping to the next line */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: small;
    gap: 10px; /* Add space between tiles */
    padding: 20px; /* Optional padding */
    color: rgb(151, 151, 184);
  
}


.new-tile-container-new {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    flex-wrap: wrap; /* Allow wrapping to the next line */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: small;
    gap: 10px; /* Add space between tiles */
    padding: 20px; /* Optional padding */
    background-color:#f3ebf8;
}



.new-tile-container-seperator{
    border-bottom: 2px solid #fff;
}

.coming-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .coming-soon-text {
    font-size: 15px;
    font-weight: bold;
    color: #6b6b6b;
    margin: 0 10px;
  }
  
  .thin-line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
    border: none;
  }
  

/* Styles for screen widths 500px and below */
@media (max-width: 500px) {
    .new-tile {
        width: 90px;
        height: 70px;
        float: inline-start;
        margin: 5px;
        border-radius: 20px;
        font-size: 10px; /* Adjust font size for smaller screens */
    }

}

/* Styles for screen widths 391px and below */
@media (max-width: 391px) {
    .new-tile {
        width: 75px;
        height: 55px;
        margin: 2px;
        border-radius: 20px;
        font-size: 10px; /* Adjust font size for smaller screens */
    }

    .new-tile .icon-for-new-tile {
        /* Fixed size */
           object-fit: contain; /* Ensures the image scales proportionally */
           padding-top: 2px;
           font-size: 20px; /* Adds spacing between the icon and text */
         }

  
    .icon {
        font-size: 80rem; /* Adjust size as needed */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem; /* Optional: Adds some spacing */
      }

}
