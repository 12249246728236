/* Container for all content */
.your-store-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px;
    padding: 30px;
}

/* Image Grid Section */
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Auto-adjust columns */
    gap: 25px;
    background-color: #f5f1f8;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
}

/* Tile Container */
.tile {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: white;
    width: 100%;
    height: 250px; /* Fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Make the Image Fully Cover the Tile */
.tile img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures full coverage */
    border-radius: 15px; /* Keeps rounded edges */
}

/* Tile Hover Effect */
.tile:hover {
    transform: scale(1.05);
    box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.3);
}

/* Tile Name Overlay */
.tile-name {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 15px;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 0 0 15px 15px;
}

/* Special Offers Section */
.special-offers-tile {
    text-align: center;
    padding: 25px;
    background: #4B0082; /* Deep Purple */
    color: #ffc107;
    border-radius: 12px;
    margin: 20px auto;
    max-width: 90%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.special-offers-tile h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.special-offers-tile p {
    font-size: 1.2rem;
    font-style: italic;
}

/* Offer Button */
.offer-button {
    background: #ffc107;
    color: #4B0082;
    border: none;
    padding: 12px 25px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    transition: background 0.3s ease, color 0.3s ease;
}

.offer-button:hover {
    background: #e65100;
    color: white;
}

/* Product Categories Section */
.product-categories-section {
    margin-top: 20px;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.product-categories-section h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #25043d;
    text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
    .image-grid {
        grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
    }

    .tile {
        height: 200px;
    }

    .tile-name {
        font-size: 1.2rem;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .image-grid {
        grid-template-columns: repeat(1, 1fr); /* One column for mobile */
    }

    .tile {
        height: 180px;
    }

    .tile-name {
        font-size: 1rem;
    }

    .offer-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}
