.important-links-container-custom { 
  font-size: small !important;
  color: #25043d !important;
  border-left: 1px solid !important;
  justify-content: center !important ;
  margin-top: 50px;
  
}

.important-links-container-custom .a {
  font-size: small !important;
  color: #25043d !important;
}

.sub-links-container {
list-style-type: none; /* Remove bullet points */
padding-left: 0; /* Remove padding on the list */
margin: 0; /* Remove margins on the list */
}

.sub-link-item {
display: list-item; /* Makes it behave like a clean list item */
padding-left:0px ;
margin-left: 0; /* Ensure no indentation */
}

.sub-link-button {
display: inline-block; /* Inline-block for consistent sizing */
color: #25043d; /* Keep the text color consistent */
text-decoration: underline; /* No underline */
padding: 2px 0; /* Minimal vertical padding for neat spacing */
}

.sub-link-button:hover {
text-decoration: underline; /* Add underline on hover for clarity */
color: #23634e;
}

.sub-link-button:before {
  content: "🔗"; /* Unicode symbol or change to FontAwesome class */
  margin-right: 6px;
}

.important-links-container {
list-style-type: none; /* Remove bullet points */
padding: 0;
}

.important-link-button {
display: block;
width: 80%; /* Full width within the container */
max-width: 180px; /* Consistent width for a sleek look */
margin: 4px auto; /* Space between the buttons */
padding: 8px 10px; /* Internal padding for a thinner appearance */
background-color: #25043d; /* Background color consistent with theme */
color: white; /* Button text color */
text-align: center; /* Center the text */
text-decoration: none; /* Remove underline */
border-radius: 10px; /* Rounded corners for a modern look */
font-size: 10px; /* Sleek font size */
font-weight: 500; /* Slightly bold for readability */
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
transition: background-color 0.3s ease, box-shadow 0.3s ease;

}

.important-link-button:hover {
background-color: #6A0DAD; /* Lighter hover color */
transform: translateY(-4px); /* Lift effect */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.important-link-button:active {
background-color: #3A006B; /* Darker color when clicked */
box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2); /* Inset shadow for click effect */
}

@media (max-width: 750px) {
.important-link-button {
  max-width: 80%; /* Full width for smaller screens */
  padding: 8px; /* Extra padding for easier taps on mobile */
}
}

.popular-sites-container {
list-style-type: none; /* Remove bullet points */
padding: 0;
}

.popular-site-button {
display: block;
width: 80%; /* Ensure it stays within the container */
max-width: 180px; /* Reduce the width for a thinner look */
margin: 4px auto; /* Reduce the space between buttons */
padding: 6px 10px; /* Reduce internal padding for a thinner appearance */
background-color: #25043d; /* Keep the color */
color: white;
text-align: center;
text-decoration: none;
border-radius: 10px; /* Keep rounded corners */
font-size: 10px; /* Slightly smaller font for a sleeker look */
font-weight: 500;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a modern look */
transition: background-color 0.3s ease, box-shadow 0.3s ease;

}

.popular-site-button:hover {
background-color: #4a2168; /* Slightly lighter purple on hover */
transform: translateY(-4px); /* Lift effect */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow on hover */
}

.popular-site-button:active {
background-color: #3A006B; /* Darker when clicked */
box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Media queries to ensure mobile view is also adjusted */
@media (max-width: 750px) {
.popular-site-button {
    max-width: 100%; /* Full width for smaller screens */
    padding: 8px; /* Slightly more padding on mobile for easier taps */
}
}


@media (min-width: 900px) {
  .important-links-container-custom {
      font-size: medium !important;
      color: #25043d !important;
      
     
  }
}


@media (max-width: 750px) {
  .important-links-container-custom {
    display: flex;
    flex-wrap: nowrap; /* Keep the columns side by side */
    gap: 10px; /* Add spacing between the columns */
    align-items: flex-start; /* Align content at the top */
  }

  .important-links-list,
  .popular-sites-list {
    display: flex;
    flex-direction: column; /* Stack header and list vertically */
    flex: 1; /* Equal width for each column */
    padding: 0 5px; /* Add padding for better spacing */
    margin: 0; /* Remove default margins */
    margin-top: -20px;

  }

  .important-links-list h4,
  .popular-sites-list h4 {
    text-align: center; /* Center-align the headers */
    margin-bottom: 8px; /* Add space below the headers */
  }

  ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }

  li {
    margin: 2px 0; /* Add spacing between list items */
  }

  .popular-sites-list {
    border-left: 1px solid #25043d; /* Add a vertical divider */
  }
}