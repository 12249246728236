/* src/SignInModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(191, 22, 22, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 150px auto; /* Increase this value to lower the modal */
  position: relative; /* Ensure relative positioning for proper layout */
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
}

.modal-content {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.error-message {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.show-password-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.show-password-container label {
  margin-left: 5px;
}
.character-count {
  font-size: 0.8em; /* Adjust the font size as needed */
  color: red; /* Change the color to red */
  margin-top: 5px;
}

@media (max-width: 768px) {
  .small-modal {
    margin: 200px auto; 
    position: relative;
  }
}