/* AfricanVillage.css */

.african-village {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #001f3f, #003366);
  color: white;
  text-align: center;
  padding: 20px;
}

.hero {
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.hero-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: rgb(235, 205, 38);
}

.hero-subtitle {
  font-size: 1.3rem;
  margin-top: 10px;
  color: rgb(235, 205, 38);
  
}

.order-button {
  margin-top: 15px;
  background: rgb(234, 234, 181);
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
}

.order-button:hover {
  background: orange;
}

.about h2, .address h3, .features h3 {
  font-size: 1.8rem;
  color: rgb(247, 236, 172);
}

.about p, .address p, .feature-box ul li {
  font-size: 1.1rem;
  color: rgb(247, 236, 172);
}

.address, .features {
  margin-top: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.slim-box {
  padding: 10px;
}

.feature-box ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature-box ul li {
  margin: 5px 15px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  margin-top: 20px;
  padding: 10px;
}

.gallery-item {
  overflow: hidden;
  border-radius: 10px;
}

.gallery-img {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.gallery-img:hover {
  transform: scale(1.1);
}

.call-to-order {
  font-size: 1.1rem;
  margin: 10px 0;
}

.discount-note {
  color: #22c9a5;
  font-weight: bold;
}

.menu-link {
  display: inline-block;
  background-color: #084126;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 10px;
}

.menu-link:hover {
  background-color: #9b2226;
}

@media (max-width: 768px) {
  .hero {
    height: 45vh;
    padding: 10px;
  }
  .hero-title {
    font-size: 1.2rem;
  }
  .hero-content {
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 110px;
    max-width: 90%;
  }
  .hero-subtitle {
    font-size: 1rem;
  }
  .order-button {
    font-size: 0.8rem;
    padding: 6px 14px;
  }
  .about p, .address p, .feature-box ul li {
    font-size: 0.9rem;
  }
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}


@media (min-width: 360px) {
  .hero {
    height: 45vh;
    padding: 10px;
  }
  .hero-title {
    font-size: 1.2rem;
  }
  .hero-content {
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 200px;
    max-width: 90%;
  }
  .hero-subtitle {
    font-size: 1rem;
  }
  .order-button {
    font-size: 0.8rem;
    padding: 6px 14px;
  }
  .about p, .address p, .feature-box ul li {
    font-size: 0.9rem;
  }
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}
