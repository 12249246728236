
.slider-holder {
    width: 90%; /* Adjust container width based on screen size */
    max-width: 90vw;; /* Optional: Limit max width for larger screens */
    background: #25043d;
    border: 1px solid #867979;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 2%;
  }

  .slider-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 10px;
    width: 100%; /* Matches the container width */
    max-width: 100%; /* Ensures it doesn't exceed the container */
  }

  /* Hide scrollbar */
  .slider-container {
    scrollbar-width: none; /* For Firefox */
  }

  .slider-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }

  .slider-container-tile {
    flex: 0 0 120px; /* Fixed tile width */
    height: 40px; /* Fixed tile height */
    color: #1b0d0d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    border-radius: 16px;
    border-style: inset;
    scroll-snap-align: start;
    flex-direction: row;
    background-color: #e7deee;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 123, 255, 0.5); /* Transparent background */
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    z-index: 1;
  }

  .arrow.left {
    left: -10px;
  }

  .arrow.right {
    right: -10px;
  }

  .arrow:hover {
    background-color: rgba(0, 86, 179, 0.7); /* Slightly darker transparent background */
  }

  @media (min-width: 750px){
    .slider-holder {
        display: none !important;
    }
  }

  /* Responsive Design */
  @media (max-width: 750px) {
    .slider-holder {
      width: 90%; /* Adjust container width for tablets */
      padding: 15px;
    }
  }

  @media (max-width: 480px) {
    .slider-holder {
      width: 90%; /* Full-width container for small screens */
      padding: 10px;
    }
  }