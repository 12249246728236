.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #3d0e5f;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .contact-form textarea {
    height: 100px;
  }
  
  .char-count {
    font-size: 0.9rem;
    color: #888;
    text-align: right;
  }
  
  .attachment-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .attachment-container label {
    flex: 1;
    color: #333;
  }
  
  .attachment-container input {
    flex: 2;
  }
  
  .file-name {
    margin-left: 10px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .cancel-button {
    background: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button:hover {
    background: #c0392b;
  }
  
  .contact-form button {
    background: #3d0e5f;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .contact-form button:hover {
    background: #2c0942;
  }
  