.vadu-aplu-container {
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 30px;
  background: linear-gradient(135deg, #130120, #8a2be2);
  color: white;
  min-height: 100vh;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.tagline {
  font-size: 1.2rem;
  font-weight: 300;
  color: #ffd700;
}

.services, .contact {
  margin-top: 30px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  font-size: 1.2rem;
  margin: 10px 0;
}

.contact p {
  font-size: 1.2rem;
}

.contact a {
  color: #1f1b03;
  text-decoration: none;
  font-weight: bold;
}

.cta-button {
  display: inline-block;
  background: #ffd700;
  color: black;
  font-size: 1rem;
  padding: 10px 20px;
  margin-top: 15px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: 0.3s;
}

.cta-button:hover {
  background: #ffcc00;
}
