/* Left Column */
.left-column {
   /* width: 80%; /* Narrow the column 
    max-width: 300px; Optional: Set a maximum width */
    padding: 10px;
    background-color: #25043d; /* Match background color */
    color: #ffffff; /* Text color */
    /*display: flex;
    flex-direction: column;*/
    gap: 10px; /* Space between sections */
  }

  .margin-
   {
    margin-top: 0px !important; /* Add a 40px top margin */
  }
  
  
  /* Section Heading */
  .section-heading {
    text-align: center;
    color: #ffc107; 
    font-size: 0.8rem; /* Slightly smaller font size */
    margin: 0;
  
    margin-bottom: 5px;
  }
  
  /* Divider */
  .divider {
    border: 1px solid #ccc;
    margin: 10px 0;
  }
  
  /* Frequently Visited and Countries List */
  .frequent-list,
  .country-list {
    /*display: flex;
    flex-direction: column;*/
    gap: 10px; /* Space between items */
    margin: 0;
    padding: 0;
  }
  
  /* Button Style */
  .flag-button {
    display: flex;
    flex-direction: row; /* Align flag and text side by side */
    align-items: center; /* Center flag and name vertically */
    justify-content: flex-start; /* Align content to the left */
    width: 100%; /* Full width of the column */
    height: 40px; /* Make buttons smaller */
    border: 1px solid #25043d; 
    border-radius: 5px; /* Slightly rounded corners */
    background-color: #e7deee;  /* Yellow background */
    cursor: pointer;
    transform: translateY(-4px); /* Lift effect */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
    padding: 5px;
   
  }
  
  /* Flag Icon */
  .flag-icon {
    font-size: 1.2rem; /* Adjust flag size */
    margin-right: 10px; /* Space between flag and text */
  }
  
  /* Flag Name */
  .flag-name {
    font-weight: bold;
    font-size: 0.8rem; /* Slightly smaller font size */
    color: #0f0101 !important; /* Text color */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for long names */
  }
  
  @media (max-width: 750px) {

      /* Frequently Visited and Countries List */
      .frequent-list,
      .country-list {
        /*display: flex;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        flex-wrap: wrap; /* Allow wrapping to the next line */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        gap: 10px; /* Space between items */
        margin: 0;
        padding: 0;
      }

      .left-column {
       display: none !important;
      }
     

  }