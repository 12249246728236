.your-store-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    gap: 20px;
    padding: 20px;
}

/* Image Grid Section */
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Two columns for tiles */
    gap: 20px;
    background-color: #f5f1f8; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
}

.tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #43096c;
    width: 80px; /* Fixed width */
    height: 100px; /* Fixed height */
    padding: 10px;
    border: 1px solid #ffc107; 
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(58, 4, 65, 0.1);
    font-size: 0.8rem;
    font-family: sans-serif;
    color: #ffc107; /* White text for contrast */
    text-align: center;
}

.tile:hover {
    background-color: #5a0a8c; /* Slightly lighter shade on hover */
    box-shadow: 0px 4px 8px rgba(58, 4, 65, 0.2);
}

.tile-image {
    width: 100%; /* Make image responsive */
    max-width: 140px; /* Limit max width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the image scales without distortion */
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.tile-name {
    font-weight: bold;
    color: #ffc107; 
}

/* List Tile Section */
.list-tile {
    background-color: #571e7f; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.list-tile h3 {
    margin-bottom: 10px;
    color: #007bff;
}

.list-tile ul {
    list-style: disc;
    padding-left: 20px;
}

.list-tile li {
    margin: 5px 0;
    color: #333;
}

/* Special Offers Tile Section */
.special-offers-tile {
    background-color: #3b0958;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.special-offers-tile h3 {
    color: #ffc107; /* Gold color */
  text-shadow: 
    1px 1px 0 #4b0082,  /* Indigo shadow */
    2px 2px 0 #4b0082,
    3px 3px 0 #4b0082,
    4px 4px 0 #7e1dc4; /* Adds depth */
  letter-spacing: 1px; /* Space between letters */

}

.special-offers-tile p {
    margin-bottom: 15px;
    color: #ffc107; 
    font-size: medium;
}

/* Vendor Templates */
.vendor-template1, .vendor-template2, .vendor-template3 {
    background-color: #3b0958; /* Adjust colors as needed */
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.vendor-template1 h3, .vendor-template2 h3, .vendor-template3 h3 {
    color: #e67e22;
    margin-bottom: 10px;
}

.vendor-template1 p, .vendor-template2 p, .vendor-template3 p {
    margin-bottom: 15px;
    color: #444;
}

/* Offer Button */
.offer-button {
    background-color: #ffc107;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.offer-button:hover {
    background-color: #d35400;
}


/* Media Query for Smaller Screens */
@media (max-width: 768px) {
    .your-store-container {
        grid-template-columns: 1fr; /* Stack blocks vertically */
        gap: 15px;
        padding: 10px;
    }

    .image-grid {
        grid-template-columns: repeat(2, 1fr); /* Two columns for smaller screens */
        gap: 15px;
    }

    .tile {
        width: 100%; /* Adjust to fit container width */
        height: auto; /* Auto height for responsiveness */
    }

    .tile-image {
        max-width: 60px; /* Smaller images for mobile */
        height: auto;
    }

    .offer-button {
        width: auto;
        padding: 8px 15px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .your-store-container {
        padding: 5px;
    }

    .tile {
        max-width: 100%; /* Full width for very small screens */
    }

    .tile-image {
        max-width: 60px; /* Further reduce image size */
    }

    .offer-button {
        padding: 6px 10px;
        font-size: 0.8rem;
    }
}
