.signup-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .signup-container h2 {
    text-align: center;
    color: #333;
  }
  
  label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  input, select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .terms-box {
    height: 150px;
    overflow: auto;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    background: #fff;
  }
  
  .button-terms {
    background: #28a745;
    color: white;
    padding: 10px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
  }
  
  button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .back-to-home {
    text-align: center;
    margin-top: 15px;
  }
  
  .back-to-home a {
    color: #4d0963;
    text-decoration: none;
  }
  
  .back-to-home a:hover {
    text-decoration: underline;
  }
  .terms-box {
    max-height: 250px; /* Increased height */
    overflow-y: auto; /* Enables scrolling */
    border: 1px solid #ccc;
    padding: 15px;
    margin: 10px 0;
    background-color: #f9f9f9;
    font-size: 14px;
    line-height: 1.8; /* Increases line spacing for better readability */
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    text-align: left;
    white-space: pre-line; /* Ensures new lines are properly formatted */
  }
  
  .terms-box ol {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .terms-box li {
    margin-bottom: 10px; /* Adds space between list items */
  }
  
  .terms-box p {
    margin-bottom: 15px; /* Spacing between paragraphs */
  }
  
  /* Center the form and make it responsive */
.signup-container {
    width: 100%;
    max-width: 500px; /* Keeps form compact on larger screens */
    margin: 40px auto;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Styling input fields */
  .signup-container input[type="text"],
  .signup-container input[type="email"],
  .signup-container input[type="tel"],
  .signup-container select {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.3s;
  }
  
  .signup-container input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  }
  
  /* Styling the label */
  .signup-container label {
    font-weight: 600;
    display: block;
    margin-top: 10px;
  }
  
  /* Centering the radio buttons horizontally */
  .radio-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Adds space between Yes/No */
    margin: 10px 0;
  }
  
  /* Styling radio buttons */
  .radio-group input {
    margin-right: 5px;
  }
  
  /* Submit button styling */
  .signup-container button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    transition: 0.3s;
  }
  
  .signup-container button:disabled {
    background: gray;
    cursor: not-allowed;
  }
  
  .signup-container button:hover {
    background: #0056b3;
  }
  
  /* Styling the "Back to Home" link */
  .back-to-home {
    text-align: center;
    margin-top: 15px;
  }
  
  .back-to-home a {
    color: #310239;
    text-decoration: none;
    font-weight: bold;
  }
  
  .back-to-home a:hover {
    text-decoration: underline;
   
  }
  
  .back-to-home-Thank-You  {
    color: #310239 !important;
  }