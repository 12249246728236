.vendor-template2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f3f3f3;
}

.gradient-background {
  width: 90%;
  max-width: 1200px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(to right, #842c91, #e17326); /* Gradient colors */
  color: white;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Logo Section */
.logo-section {
  margin-bottom: 20px;
}

.vendor-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid white;
}

.vendor-name {
  font-size: 1.8rem;
  margin-top: 10px;
}

/* About Section */
.about-section {
  margin-bottom: 20px;
}

.about-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1rem;
  margin: 5px 0;
}

.email-button {
  background-color: #e17326;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

/* Info and Specialties */
.content-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  color: #f3f3f3;
}

.info-section,
.specialties-section {
  width: 45%;
  text-align: left;
  color: #ffd700; /* Gold color for headings */
}

.info-section h3,
.specialties-section h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #ffd700; /* Gold color for headings */
}

.specialties-section ul {
  list-style: none;
  padding: 0;
}

.specialties-section ul li {
  margin: 5px 0;
}

/* Product Gallery */
.product-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.product-gallery img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
